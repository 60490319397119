// //Доступный hamburger https://foxland.fi/simple-accessible-svg-menu-hamburger-animation
// function hamburger(element, menu) {
// 	var button = document.getElementById(element),
// 		menu = document.getElementById(menu);
// 	button.onclick = function () {
// 		// Toggle class "opened". Set also aria-expanded to true or false.
// 		if (-1 !== button.className.indexOf('opened')) {
// 			button.className = button.className.replace(' opened', '');
// 			button.setAttribute('aria-expanded', 'false');
// 			menu.className = menu.className.replace(' active', '');
// 			menu.setAttribute('aria-expanded', 'false');
// 		} else {
// 			button.className += ' opened';
// 			button.setAttribute('aria-expanded', 'true');
// 			menu.className += ' active';
// 			menu.setAttribute('aria-expanded', 'true');
// 		}
// 	};
// }

$(document).ready(function () {

	window.globalPopup = new Popup();


	// $(".js-combox").combox({
	// 	startFn: function(li, index, combox) {

	// 		this.input = combox.getElementsByTagName("input")[0];

	// 		this.input.value = li.getAttribute("value");

	// 	},
	// 	changeFn: function(li, index, combox) {

	// 		var _this = this;

	// 		this.input.value = li.getAttribute("value");

	// 	}
	// });

	// hamburger('js-hamburger', "js-menu");

	console.log('loaded');

	$('.js-faq-acordion').on('click', '.faq-acordion__list-btn', function (e) {
		e.preventDefault();
		var parent = $(this).parents('.js-faq-acordion');
		if(!$(this).hasClass('active')) {
			parent.find('.faq-acordion__list-btn').removeClass('active');
			$(this).addClass('active');
			parent.find('.faq-acordion__list-cont').hide(300);
			$(this).next().show(300);
		} else {
			$(this).removeClass('active');
			$(this).next().hide(300);
		}
	});

	//region side menu
	var slideout = new Slideout({
		'panel': document.getElementById('js-wrapper'),
		'menu': document.getElementById('side-menu'),
		'padding': 272,
		'tolerance': 70,
		side: 'right',
		touch: false
	});
	document.querySelector('#js-hamburger').addEventListener('click', function() {
		slideout.toggle();
	});
	//endregion

	$('.js-search-open').click(function (e) {
		e.preventDefault();
		$('.js-search').slideDown(300);
	});
	$('.js-search-close').click(function (e) {
		e.preventDefault();
		$('.js-search').slideUp(300);
	});

	$('.js-faq__slide-col').each(function(i) {
		$(this).click(function() {
			$(this.parentNode).stop().animate({scrollLeft: (i * this.offsetLeft) - 10}, 500);
		});
	});

	//region main slider

	$('.js-slider-main').on('init', function(slick) {
		for (var i = 0; i < slick.currentTarget.children[1].children.length; i++) {
			var name = $(this).find('.slick-slide').eq(i).find('[data-title]').attr('data-title');
			var title = '<span class="dot-text">' + name + '</span>';
			var line = document.createElement('div');
			line.className = 'slider-main__line';
			slick.currentTarget.children[1].children[i].children["0"].innerHTML = title;
			// slick.currentTarget.children[1].children[i].appendChild(line);
			slick.currentTarget.children[1].children[i].insertBefore(line, slick.currentTarget.children[1].children[i].children["0"]);
			// debugger
		}
	});




	$('.js-slider-main').on('wheel', (function(e) {
		e.preventDefault();

		if (e.originalEvent.deltaY > 0) {
			$(this).slick('slickNext');
		} else {
			$(this).slick('slickPrev');
		}
	}));


	$('.js-slider-main').slick({
		slidesToShow: 1,
		dots: true,
		dotsAlt: true,
		arrows: false,
		adaptiveHeight: true,
		infinite: false,
		fade: true
	});
	//endregion

	if($('.js-faq').length) {
		$('.js-faq .faq__slide').each(function (idx, val) {
			if(idx != 0) {
				$(this).hide();
			}
		});
		$('.js-faq').on('click', '.js-faq__tab-link', function (e) {
			e.preventDefault();
			var id = $(this).attr('href').replace('#', '');
			$('.js-faq').find('.faq__slide').each(function (e) {
				if($(this).attr('id') == id) {
					$(this).show();
				} else {
					$(this).hide();
				}
			})
		});
	}

	$("[type=tel]").inputmask("+7 (999) 999-99-99");

	// Прибивка адаптивного футера к низу
	(function (footerSelector, wrapperSelector) {

		var footer = document.querySelector(footerSelector);
		var wrapper = document.querySelector(wrapperSelector);
		var height;
		var setSize;

		if (!wrapper || !footer) {
			return false;
		}

		setSize = function () {

			height = footer.offsetHeight;

			wrapper.style.paddingBottom = height + 'px';
			footer.style.marginTop = (height * (-1)) + 'px';

		}

		setSize();

		window.addEventListener('resize', setSize, false);

	})('#js-footer', '#js-wrapper');

	$(document).on('click', '.js-form-validation input[type=submit]', function (e) {
		e.preventDefault();
		var count = 0;
		var form = $(this).closest('form');

		form.find('.js-req').each(function (e) {
			if($(this).val().length == 0) {
				$(this).parent().addClass('error');
				count++;
			}
		});

		if(count == 0) {
			form.submit();
		}

	});

	$(document).on('focus', '.js-req', function (e) {
		if ($(this).parent().hasClass('error')) {
			$(this).parent().removeClass('error');
		}
	});

});
